import React from 'react';
import './MediaCarousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {VideoExtensions} from "../../constants/VideoExtensions";

class MediaCarousel extends React.Component{

    constructor(props) {
        super(props);
        this.slider = React.createRef();
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }

    play() {
        this.slider.slickPlay();
    }

    pause() {
        this.slider.slickPause();
    }

    isMediaTypeVideo(media) {
        let mediaIsVideo = false
        VideoExtensions.forEach(extension => {
            if (media.toLowerCase().includes(extension)) {
                mediaIsVideo = true;
            }
        });
        return mediaIsVideo;
    }

    render() {

        const {title, description, media} = this.props;

        const carouselSettings = {
            dots: true,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: false,
            pauseOnDotsHover: true, // Also pauses when clicked
            onSwipe: this.pause
        };

        return (
            <div>
                <Slider className={`media-carousel ${media?.filter(Boolean)?.length > 10 ? "smaller-dots" : ""}`} ref={slider => (this.slider = slider)} {...carouselSettings}>
                    {media.map((media, index) => {
                        if(media){
                            if(this.isMediaTypeVideo(media)){
                                return <div key={index} className="media-carousel-video-slide-container">
                                    <video className="media-carousel-video" autoPlay={true} loop={true} muted={true} playsInline={true}>
                                        <source src={media}/>
                                    </video>
                                </div>
                            } else {
                                return <div key={index} className="media-carousel-image-slide-container">
                                    <img className="media-carousel-image" src={media} alt=""/>
                                </div>
                            }
                        }
                        return null;
                    })}
                </Slider>

                { (title && description) &&
                    <div className="media-carousel-text-container">
                        <h2 className="media-carousel-title">{title}</h2>
                        <div className="media-carousel-description" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                }
            </div>
        );
    }
}

export default MediaCarousel;