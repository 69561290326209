import React from "react";
import './BlogPage.css';
import Navigation from "../../components/navigation/Navigation";
import {getBlogPostRequest, getBlogsRequest, getFooterRequest} from "../../networking/Requests";
import Footer from "../../components/footer/Footer";
import CardSlider from "../../components/slider/CardSlider";
import {SliderAlign} from "../../constants/SliderAlign";
import {getI18n, withTranslation} from "react-i18next";
import MediaCarousel from "../../components/media-carousel/MediaCarousel";
import Decoration1 from '../../resources/images/page-decoration-6.svg';
import Decoration2 from '../../resources/images/page-decoration-7.svg';
import Loading from "../../components/loading/Loading";
import MetaTags from "react-meta-tags";
import Card from "../../components/slider/Card";
import Arrow from "../../resources/images/arrow-right.svg";

class BlogPage extends React.Component {

    state = {}

    constructor(props){
        super(props);
        if(props.lang && props.lang !== getI18n().language){
            getI18n().changeLanguage(this.props.lang);
        }
    }

    async componentDidMount() {
        const slug = this.props.match.params.slug;

        Promise.all([
            getFooterRequest(),
            getBlogPostRequest(slug),
            getBlogsRequest(4, 1)
        ])
        .then(result => {
            const [footerData, blogPost, blogsData] = result;

            this.setState({
                slug: slug,
                footerData: footerData,
                blogPost: blogPost,
                blogsData: blogsData
            });
        })
    }

    getRedirectPath(){
        let redirectPath = "";

        if(getI18n().language === 'en'){
            redirectPath += "/en/";
        } else {
            redirectPath += "/";
        }

        redirectPath += "blogs";

        return redirectPath;
    }

    createBlogCards(){
        let cardArray = [];
        let blogPosts = this.state.blogsData.blogPosts.filter( blog => { return blog.slug !== this.state.slug; })

        blogPosts.slice(0, 3).forEach( blog => {
            const blogData = {
                date: blog.date,
                year: blog.year,
                month: blog.month,
                day: blog.day
            }

            cardArray.push(
                <Card
                    key={blog.id}
                    id={blog.slug}
                    title={blog.title}
                    description={blog.intro}
                    imageUrl={blog.attachment1}
                    buttonText={blog.buttonText}
                    type={'blog'}
                    blogData={blogData}
                />
            )
        });

        return cardArray;
    }

    render(){
        const {blogsData, blogPost, footerData} = this.state;

        if(!footerData || !blogsData || !blogPost){
            return (
                <Loading />
            );
        }

        return(
            <div>
                <MetaTags>
                    <meta property="og:title" content="De Dorus - Blogs"/>
                    <title>De Dorus - Blogs</title>
                    <link rel="canonical" href="https://dedorus.nl/blogs" />
                </MetaTags>

                <Navigation
                    {...this.props}
                    socials={footerData.socials}
                />

                <main className={"blog-detail-section"}>
                    <div className={"blog-container"}>
                        <div className="blog-text-container">
                            <a className="blog-back-button" href={this.getRedirectPath()}>
                                <img src={Arrow} alt="arrow-left" style={{transform: 'rotate(-180deg)'}}/>
                                <span>{blogsData.backButtonText}</span>
                            </a>

                            <h1 className="blog-title">{blogPost.title}</h1>
                            <div className="blog-date-container">
                                <span className="blog-date-year">{blogPost.year}</span>
                                <span className="blog-date-monthday">{blogPost.month + ' ' + blogPost.day}</span>
                            </div>
                            <div className="blog-intro" dangerouslySetInnerHTML={{__html: blogPost.intro}}/>
                        </div>

                        <section className="media-carousel-section">
                            <img id="about-page-decoration-1" src={Decoration1} alt="decoration"/>
                            <img id="about-page-decoration-2" src={Decoration2} alt="decoration"/>
                            <MediaCarousel
                                title={blogPost.subtitle}
                                description={blogPost.description}
                                media={[
                                    blogPost.attachment1,
                                    blogPost.attachment2,
                                    blogPost.attachment3,
                                    blogPost.attachment4,
                                    blogPost.attachment5,
                                    blogPost.attachment6,
                                    blogPost.attachment7,
                                    blogPost.attachment8,
                                    blogPost.attachment9,
                                    blogPost.attachment10,
                                    blogPost.attachment11,
                                    blogPost.attachment12,
                                    blogPost.attachment13,
                                    blogPost.attachment14,
                                    blogPost.attachment15,
                                ]}/>
                        </section>
                    </div>

                    <section id="blogs" className="blogs-section">
                        <CardSlider
                            cards={this.createBlogCards()}
                            title={blogsData.relatedTitle}
                            subTitle={blogsData.relatedSubtitle}
                            description={''}
                            orientation={SliderAlign.RIGHT}
                            color="#E4ECDF"
                            decoration={true}
                            type={'blogs'}
                            buttonText={blogsData.sliderButtonText}
                        />
                    </section>
                </main>

                <Footer data={footerData}/>
            </div>
        );
    }
}

export default withTranslation()(BlogPage);